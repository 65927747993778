import Swal from "sweetalert2";
import DemandeArretsService from "../services/DemandeArretsService";
import { afficherOneMessageError } from "../utils/helpers";

export const DemandeArretStore = (
  code_transaction,
  paiementsInfo,
  token,
  setLoading,
) => {
  DemandeArretsService.storePaiement(code_transaction, paiementsInfo, token)
    .then((res) => {
      if (res.status === 200) {
        //setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Paiement effectué avec succès !",
          //text: res.data.data.transaction.message,
        });
        // Actualiser la page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (res.data.data.echec) {
        setLoading(false); // Fin du chargement en cas d'erreur de validation

        Swal.fire({
          icon: "warning",
          title: "Une erreur s'est produite !",
          text: res.data.data.echec,
        });
      } else if (res.data.erreur) {
        setLoading(false); // Fin du chargement en cas d'erreur de validation

        Swal.fire({
          icon: "error",
          title: "Erreur!",
          text: res.data.message,
        });
      }
    })
    .catch((error) => {
      setLoading(false); // Fin du chargement en cas d'erreur de validation
      //("Error", error.response?.data?.data);
      afficherOneMessageError(error.response?.data?.data || "Erreur lors de l'enregistrement du paiement !")
    });
};
