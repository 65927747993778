import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/styles.css";
import { myroutes } from "../routes/webroute";
import {} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ModesPaiementsService from "../services/ModesPaiementsService";
import DemandeArretsService from "../services/DemandeArretsService";
import Swal from "sweetalert2";

import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { RadioButton } from "primereact/radiobutton";
import {
  afficherErrorToast,
  afficherMessagesSuccess,
  afficherOneMessageError,
  formatAmount,
  getPaiementConfig,
  initiateFedaPayCheckout,
} from "../utils/helpers";
//import { MobileOnlyView, isBrowser, isTablet } from "react-device-detect";
import FraisPaiementsService from "../services/FraisPaiementsService";
import useMediaQuery from "@mui/material/useMediaQuery";
import NatureAffaire from "../services/NatureAffaireService";
import { checkAuthenticate, resetAuthData } from "../app/providers/authSlice";
import { DemandeArretStore } from "../lib/DemandeArretStore";
import PaymentHandler from "../lib/PaymentHandler";

///## Schema de validation
const schema = Joi.object({
  nom: Joi.string().min(2).max(255).required().messages({
    "string.empty": "Le champ Nom est obligatoire",
    "string.min": "Le nom doit contenir au moins 2 caractères",
    "string.max": "Le nom ne peut pas dépasser 255 caractères",
  }),
  /*prenoms: Joi.string().min(3).max(255).required().messages({
    "string.empty": "Le champ Prénoms est obligatoire",
    "string.min": "Le prénom doit contenir au moins 3 caractères",
    "string.max": "Le prénom ne peut pas dépasser 255 caractères",
  }),*/
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "Le champ Email est obligatoire",
      "string.email": "Veuillez entrer un email valide",
    }),
  telephone: Joi.string()
    //.pattern(/^[0-9]+$/)
    .required()
    .messages({
      "string.empty": "Le champ Téléphone est obligatoire",
      "string.pattern.base": "Le numéro de téléphone doit être valide",
    }),
  num_arret: Joi.string().min(3).max(100).required().messages({
    "string.empty": "Le champ Numéro de l'arrêt est obligatoire",
    "string.min": "Le numéro de l'arrêt doit contenir au moins 3 caractères",
    "string.max": "Le numéro de l'arrêt ne peut pas dépasser 100 caractères",
  }),
  date_arret: Joi.date().required().messages({
    "date.base": "Veuillez entrer une date valide",
    "any.required": "La date de l'arrêt est obligatoire",
  }),
  reference_dossier: Joi.string().min(3).max(100).required().messages({
    "string.empty": "Le champ Référence du dossier est obligatoire",
    "string.min": "La référence du dossier doit contenir au moins 3 caractères",
    "string.max": "La référence du dossier ne peut pas dépasser 100 caractères",
  }),
  type_demande: Joi.string().required().messages({
    "string.empty": "Veuillez sélectionner un type de demande",
  }),
  nature_affaire_id: Joi.string().required().messages({
    "string.empty": "Veuillez sélectionner la nature de l'affaire",
  }),
  modepaiement_id: Joi.string().required().messages({
    "string.empty": "Veuillez sélectionner le mode de paiement",
  }),
});

export default function DemandeArrets() {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  const [email, setEmail] = useState(
    user && user.email ? user?.email : user.email_pro
  );
  const [telephone, setTelephone] = useState(user?.telephone);
  const [nom, setNom] = useState(user?.nom);
  const [prenoms, setPrenoms] = useState(
    user && user.prenoms != "N/A" ? user?.prenoms : ""
  );
  const [num_arret, setNumArret] = useState("");
  const [nature_affaire, setNatureAffaire] = useState("");
  const [reference_dossier, setReferenceDossier] = useState("");
  const [date_arret, setDateArret] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const navigate = useNavigate();
  const [showModePaiement, setshowModePaiement] = useState(false);
  const [modespaiements, setModesPaiements] = useState([]);
  const [mode_paiement_id, setModePaiement_Id] = useState("");
  const [montant_payer, setMontantPayer] = useState("");
  const [loading, setLoading] = useState(false);
  const [cabinet, setCabinet] = useState(user?.raison_sociale);
  const [frais_paiements, setFraisPaiements] = useState([]);
  const [frais_paiement_id, setFraisPaiementId] = useState("");

  //Nature de l'affaire
  const [nature_affaires, setNatureAffaires] = useState([]);
  const [nature_affaire_id, setNatureAffaireId] = useState("");

  const isMobile = useMediaQuery("(max-width:600px)");
  const isBrowser = useMediaQuery("(min-width:600px)");
  const [validationErrors, setValidationErrors] = useState({});

  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  // check if user is authenticate
  dispatch(checkAuthenticate());
  // Vérifier si le token existe et n'est pas expiré
  if (!auth.isAuthenticate) {
    //deconnecter l'utilisateur et renvoi sur login page
    dispatch(resetAuthData());
    navigate(myroutes.login_for_demandes);
  }

  //get liste nature affaire
  const fetchNatureAffaires = async () => {
    await NatureAffaire.indexFrontoffice(token)
      .then((response) => {
        //Récupérer uniquement les éléments actif
        setNatureAffaires(
          response.data.data.filter((mode) => mode.statut_id === 1)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste mode de paiement
  const fetchModepaiements = async () => {
    await ModesPaiementsService.indexFrontoffice(token)
      .then((response) => {
        //Récupérer uniquement les modes de paiements actif
        setModesPaiements(
          response.data.data.filter((mode) => mode.statut_id === 1)
        );
        //Recupérer par defaut le mode de paiement kkiapay
        /*setModePaiement_Id(
          response.data.data.filter((mode) => mode.code === "KKIAPAY")[0].code
        );*/
        //console.log(mode_paiement_id)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste frais paiement
  const fetchFraisPaiements = async () => {
    await FraisPaiementsService.indexFrontoffice(token)
      .then((response) => {
        //Récupérer uniquement les actif
        setFraisPaiements(
          response.data.data.filter(
            (mode) => mode.statut_id === 1 && mode.libelle !== "Dossier"
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (value, country) => {
    setTelephone(value);
    setTelephoneError("");
  };

  const handleSetModePaiement = (e) => {
    setModePaiement_Id(e.target.value);
  };

  const handlesetFraisPaiements = (event) => {
    setFraisPaiementId(event.target.value);
    //Récupérer le montant à payer
    const selectedOption = event.target.options[event.target.selectedIndex];
    const price = selectedOption.getAttribute("price");

    setMontantPayer(price);
    //console.log(price)
  };


  //Get kkiapay reponse after paiement success
  const successHandler = async (response) => {
    //console.log(response)
    setLoading(true); // Début du chargement
    //Recuperation du code de la transaction de kkiapay
    var code_transaction = response.transactionId;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: montant_payer,
      reference_dossier: reference_dossier,
      telephone: telephone,
      modepaiement_id: 'KKIAPAY',
      demande_id: response.data,
    };

    //Store le paiement avec les data   
    DemandeArretStore(code_transaction, paiementsInfo, token, setLoading)
  };

  //Gestion paiement FedaPay success
  const successHandlerFedaPay = async (response) => {
    //console.log(response)
    setLoading(true); // Début du chargement

    //Recuperation du code de la transaction de kkiapay
    var code_transaction = response.id;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: response.amount,
      reference_dossier: reference_dossier,
      telephone: response.payment_method?.number,
      modepaiement_id: "FEDAPAY",
      demande_id: response.description,
    };

    //Store le paiement avec les data   
    DemandeArretStore(code_transaction, paiementsInfo, token, setLoading)


  };

   //Gestion paiement TresorPay success
   const successHandlerTresorPay = async (response) => {
    setLoading(true); // Début du chargement

    //Recuperation du code de la transaction de FedaPay
    var code_transaction = response.id;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: response.amount,
      reference_dossier: reference_dossier,
      telephone: response.payment_method?.number,
      modepaiement_id: mode_paiement_id,
      demande_id: response.description,
      reference: response.reference
    };
    //console.log(paiementsInfo)
    //Store le paiement avec les data
    DemandeArretStore(code_transaction, paiementsInfo, token, setLoading)
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = async () => {
    //e.preventDefault();
    //setLoading(true); // Début du chargement
    setTelephoneError(""); // Réinitialisation des erreurs
    setValidationErrors({}); // Réinitialisation des erreurs

    // Préparation des données du formulaire
    const formData = new FormData();
    formData.append("email", email);
    formData.append("telephone", telephone);
    formData.append("nom", nom);
    formData.append("prenoms", prenoms);
    formData.append("num_arret", num_arret);
    formData.append("nature_affaire_id", nature_affaire_id);
    formData.append("date", date_arret);
    formData.append("reference_dossier", reference_dossier);
    formData.append("cabinet_avocat", cabinet);
    formData.append("frais_paiement_id", frais_paiement_id);

    try {
      // Envoi des données au backend
      const response = await DemandeArretsService.store(formData, token);

      if (response.status === 200) {
        const demande_id = response.data.data.id;

        // Vérification du mode de paiement si nécessaire
        if (showModePaiement && !mode_paiement_id) {
          setTelephoneError("Veuillez sélectionner un mode de paiement.");
          return setLoading(false);
        }
        //alert(reference_dossier)
        // Récupération de la clé et ouverture du widget de paiement
        const fullname = `${nom} ${prenoms}`;
        //Afficher le hook de paiement
        try {
          const params = {
            montant_payer,
            reference_dossier : demande_id,
            auth,
            config: getPaiementConfig(),
            successHandlerFedaPay,
            successHandlerTresorPay
          };
          PaymentHandler.executeStrategy(mode_paiement_id, params);
        } catch (error) {
          afficherErrorToast(error.message);
        }

        /*if (mode_paiement_id == "KKIAPAY") {
          const config = getPaiementConfig();
          openKkiapayWidget({
            amount: montant_payer,
            position: "center",
            data: {
              reference_dossier,
              mode_paiement_id,
              montant_payer,
              telephone,
              demande_id,
            },
            name: fullname,
            theme: "green",
            api_key: config.key,
            sandbox: config.isSandbox,
            email,
            phone: parseInt(telephone, 10),
          });
        } else {
          //Lancer FedaPay
          initiateFedaPayCheckout({
            amount: montant_payer,
            description: demande_id,
            onCompleteCallback: (resp) => {
              if (resp.reason === "dialog_dismissed") {
                afficherErrorToast("Vous avez fermé la fenêtre de paiement");
              } else {
                afficherMessagesSuccess("Transaction terminée: " + resp.reason);
              }
              successHandlerFedaPay(resp.transaction);
              //console.log(resp.transaction);
            },
          });
        }*/
      } else {
        // Gestion des erreurs renvoyées par le backend
        Swal.fire({
          icon: "error",
          text: response.data.error,
        });
      }
    } catch (error) {
      // Gestion des erreurs inattendues
      if (error && error.response && error.response.status === 404) {
        Swal.fire({
          icon: "warning",
          title: "Erreur",
          html:
            error.response?.data?.message ||
            "Une erreur inattendue s'est produite.",
          showCancelButton: true,
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
          confirmButtonColor: "green",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response =
                await DemandeArretsService.storePaiementWithoutDossierId(
                  formData,
                  token
                );
              if (response.status === 200) {
                const demande_id = response.data.data.id;
                
                try {
                  const params = {
                    montant_payer,
                    reference_dossier : demande_id,
                    auth,
                    config: getPaiementConfig(),
                    successHandlerFedaPay,
                    successHandlerTresorPay,
                  };
                  PaymentHandler.executeStrategy(mode_paiement_id, params);
                } catch (error) {
                  afficherErrorToast(error.message);
                }
                /*const fullname = `${nom} ${prenoms}`;
                const config = getPaiementConfig();

                if (mode_paiement_id == "KKIAPAY") {
                  openKkiapayWidget({
                    amount: montant_payer,
                    position: "center",
                    data: {
                      reference_dossier,
                      mode_paiement_id,
                      montant_payer,
                      telephone,
                      demande_id,
                    },
                    name: fullname,
                    theme: "green",
                    api_key: config.key,
                    sandbox: config.isSandbox,
                    email,
                    phone: parseInt(telephone, 10),
                  });
                } else {
                  //Lancer FedaPay
                  initiateFedaPayCheckout({
                    amount: montant_payer,
                    description: demande_id,
                    onCompleteCallback: (resp) => {
                      if (resp.reason === "dialog_dismissed") {
                        afficherErrorToast(
                          "Vous avez fermé la fenêtre de paiement"
                        );
                      } else {
                        afficherMessagesSuccess(
                          "Transaction terminée: " + resp.reason
                        );
                      }
                      successHandlerFedaPay(resp.transaction);
                      //console.log(resp.transaction);
                    },
                  });
                }*/
              }
            } catch (subError) {
              console.error(
                "Erreur lors de la sauvegarde sans référence :",
                subError
              );
              afficherOneMessageError(
                subError && subError.response?.data.error
              );
            }
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Erreur",
          html:
            error.response?.data?.message ||
            "Une erreur inattendue s'est produite.",
          showCancelButton: true,
        });
      }
    } finally {
      setLoading(false); // Fin du chargement
    }
  };

  const handleBack = () => {
    navigate(myroutes.login);
  };

  useEffect(() => {
    fetchModepaiements();
    fetchFraisPaiements();
    fetchNatureAffaires();
    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success", successHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <main
        style={{
          backgroundImage: "url('../NiceAdmin/assets/img/log3.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <section className="header-login mb-3">
          <div className="container p-1 pt-3">
            <div className="row align-items">
              {/**Affichage pour ordinateur et tablet */}
              {isBrowser && (
                <>
                  <div className="col-md-2">
                    <Link to={myroutes.accueil}>
                      <img
                        style={{
                          height: "100px",
                          width: "120px",
                          paddingLeft: "20px",
                        }}
                        src="/NiceAdmin/assets/img/logo-blanc.png"
                        alt="logo-blanc"
                      />
                    </Link>
                  </div>

                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <h3
                      className="fw-bold"
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                      }}
                    >
                      COUR D'APPEL DE COMMERCE - COTONOU
                    </h3>
                    <hr />
                    <p
                      className="fw-bold text-warning"
                      style={{
                        fontWeight: "lighter",
                        fontSize: "21px",
                        textAlign: "center",
                      }}
                    >
                      Plateforme d'Enrôlement et de Gestion des Procédures
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <img
                      style={{
                        height: "70px",
                        width: "230px",
                        paddingRight: "50px",
                        paddingTop: "20px",
                      }}
                      src="/NiceAdmin/assets/img/ministere.png"
                      alt="Ministère"
                    />
                  </div>
                </>
              )}
              {/**Affichage pour mobile uniquement */}
              {isMobile && (
                <>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-5">
                        <Link to={myroutes.accueil}>
                          <img
                            style={{
                              height: "100px",
                              width: "120px",
                            }}
                            src="/NiceAdmin/assets/img/logo-blanc.png"
                            alt="logo-blanc"
                          />
                        </Link>
                      </div>
                      <div className="col-7 pt-4">
                        <Link to={myroutes.accueil}>
                          <img
                            style={{
                              height: "60px",
                              width: "200px",
                            }}
                            src="/NiceAdmin/assets/img/ministere.png"
                            alt="Ministère"
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <h3
                        className="fw-bold"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        COUR D'APPEL DE COMMERCE - COTONOU
                      </h3>

                      <hr />
                      <p
                        className="fw-bold text-warning"
                        style={{
                          fontWeight: "lighter",
                          fontSize: "21px",
                          textAlign: "center",
                        }}
                      >
                        Plateforme d'Enrôlement et de Gestion des Procédures
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <br />
        <section className="mt-3">
          <div className="container mt-2">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-sm-12 align-items-center justify-content-center">
                <div className="container mt-0"></div>
                <div className="card mb-3 mt-3 p-3">
                  <div className="card-body">
                    <div className="pt-3 pb-2">
                      <div className="pt-0 pb-2 text-center mb-3">
                        <h2
                          className="card-titlexx text-center pb-0 fs-4 fw-bold"
                          style={{ color: "#3B7D82" }}
                        >
                          Demande de copie d'arrêt ou grosse
                        </h2>
                      </div>
                    </div>

                    <Form
                      className="settings-form"
                      style={{ textAlign: "left" }}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md={12} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Cabinet / SCPA <i className="text-danger"></i>
                            </Form.Label>
                            <Form.Control
                              className="border border rounded-2"
                              onChange={(e) => setCabinet(e.target.value)}
                              value={cabinet}
                              placeholder=""
                              name="cabinet"
                              aria-label="cabinet"
                              maxLength={255}
                              minLength={2}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Nom <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              {...register("nom")}
                              className={`border rounded-2 bg-body-secondary ${errors.nom} ? "is-invalid" : ""`}
                              onChange={(e) => setNom(e.target.value)}
                              value={nom}
                              placeholder=""
                              name="nom"
                              aria-label="nom"
                              maxLength={100}
                              minLength={2}
                              type="text"
                              required
                              readOnly
                            />
                            {errors.nom && (
                              <small className="text-danger">
                                {errors.nom.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Prénom (s) <i className="text-danger"></i>
                            </Form.Label>
                            <Form.Control
                              className="border rounded-2 bg-body-secondary"
                              onChange={(e) => setPrenoms(e.target.value)}
                              value={prenoms}
                              placeholder=""
                              name="prenoms"
                              aria-label="prenoms"
                              maxLength={100}
                              minLength={3}
                              type="text"
                              readOnly
                            />
                            {errors.prenoms && (
                              <small className="text-danger">
                                {errors.prenoms.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              E-mail <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              {...register("email")}
                              className={`border rounded-2 bg-body-secondary ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              placeholder=""
                              name="email"
                              aria-label="email"
                              maxLength={100}
                              minLength={3}
                              type="email"
                              required
                              readOnly
                            />
                            {errors.email && (
                              <small className="text-danger">
                                {errors.email.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Téléphone <i className="text-danger">*</i>&nbsp;
                              <i
                                className="text-info bi bi-question-circle-fill"
                                title="Veuillez choisir le drapeau et compléter votre numéro"
                              ></i>
                            </Form.Label>
                            <div>
                              <PhoneInput
                                {...register("telephone")}
                                className={`border-none rounded-2 ${
                                  errors.telephone ? "is-invalid" : ""
                                }`}
                                style={{
                                  height: "38px",
                                }}
                                defaultCountry="BJ"
                                value={telephone}
                                onChange={handleChange}
                                inputclass={`form-input`}
                                placeholder="60 00 00 00"
                                required
                              />
                            </div>
                            {errors.telephone && (
                              <small className="text-danger">
                                {errors.telephone.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Numéro de l'arrêt <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              {...register("num_arret")}
                              className={`border rounded-2 ${
                                errors.num_arret ? "is-invalid" : ""
                              }`}
                              onChange={(e) => setNumArret(e.target.value)}
                              value={num_arret}
                              placeholder=""
                              name="num_arret"
                              aria-label="num_arret"
                              maxLength={100}
                              minLength={3}
                              required
                              type="text"
                            />
                            {errors.num_arret && (
                              <small className="text-danger">
                                {errors.num_arret.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Date de l'arrêt <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              {...register("date_arret")}
                              className={`border rounded-2 ${
                                errors.date_arret ? "is-invalid" : ""
                              }`}
                              onChange={(e) => setDateArret(e.target.value)}
                              value={date_arret}
                              placeholder=""
                              name="date_arret"
                              aria-label="date_arret"
                              required
                              type="date"
                            />
                            {errors.date_arret && (
                              <small className="text-danger">
                                {errors.date_arret.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Référence du dossier{" "}
                              <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              {...register("reference_dossier")}
                              className={`border rounded-2 ${
                                errors.reference_dossier ? "is-invalid" : ""
                              }`}
                              onChange={(e) =>
                                setReferenceDossier(e.target.value)
                              }
                              value={reference_dossier}
                              placeholder=""
                              name="reference_dossier"
                              aria-label="reference_dossier"
                              maxLength={100}
                              minLength={3}
                              type="text"
                              required
                            />
                            {errors.reference_dossier && (
                              <small className="text-danger">
                                {errors.reference_dossier.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Type de demande <i className="text-danger">*</i>
                            </Form.Label>
                            <select
                              {...register("type_demande")}
                              name="type_demande"
                              id="type_demande"
                              className={`form-control form-select ${
                                errors.type_demande ? "is-invalid" : ""
                              }`}
                              onChange={handlesetFraisPaiements}
                              required
                            >
                              <option value="" selected disabled>
                                -- Sélectionner le type de demande --
                              </option>
                              {frais_paiements.map((item) => (
                                <option value={item.id} price={item.montant}>
                                  {item.description}
                                </option>
                              ))}
                            </select>
                            {errors.type_demande && (
                              <small className="text-danger">
                                {errors.type_demande.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={6} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Nature de l'affaire
                              <i className="text-danger">*</i>
                            </Form.Label>
                            <select
                              {...register("nature_affaire_id")}
                              name="nature_affaire_id"
                              id="nature_affaire_id"
                              className={`form-control form-select ${
                                errors.nature_affaire_id ? "is-invalid" : ""
                              }`}
                              onChange={(e) =>
                                setNatureAffaireId(e.target.value)
                              }
                              required
                            >
                              <option value="" selected disabled>
                                -- Sélectionner la nature de l'affaire --
                              </option>
                              {nature_affaires.map((item) => (
                                <option value={item.id}>{item.libelle}</option>
                              ))}
                            </select>
                            {errors.nature_affaire_id && (
                              <small className="text-danger">
                                {errors.nature_affaire_id.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={6} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Mode de paiement
                              <i className="text-danger">*</i>
                            </Form.Label>
                            <select
                              {...register("modepaiement_id")}
                              name="modepaiement_id"
                              id="modepaiement_id"
                              className={`form-control form-select ${
                                errors.modepaiement_id ? "is-invalid" : ""
                              }`}
                              onChange={(e) =>
                                setModePaiement_Id(e.target.value)
                              }
                              required
                            >
                              <option value="" selected disabled>
                                -- Sélectionner le mode de paiement --
                              </option>
                              {modespaiements.map((item) => (
                                <option value={item.code} id={item.code}>
                                  {item.libelle}
                                </option>
                              ))}
                            </select>
                            {errors.modepaiement_id && (
                              <small className="text-danger">
                                {errors.modepaiement_id.message}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="row mb-4 pt-2 p-fluid">
                        <div className="col-md-4">
                          <h5 className="mb-2 me-3">
                            Montant à payer <i className="text-danger">*</i> :
                          </h5>
                          <h4 className="text-success">
                            {montant_payer
                              ? formatAmount(montant_payer)
                              : "0 F CFA"}
                          </h4>
                        </div>
                        <div className="col">
                          <img
                            src="/paiement.png"
                            alt="modepaiement"
                            className="img-fluid  me-2 p-1"
                            style={{
                              height: "auto",
                              width: "300px",
                              float: "right",
                            }}
                          />
                        </div>
                      </div>

                      {/**<div className="row align-items-center justify-content-between pb-4">
                                                <div className="col-md-12 text-center">
                                                    <img src="/modepaiement.png" alt="modepaiement" className="img-fluid" style={{ height: "80px" }} />
                                                    {/**<img src="/mtnmomo.jpg" alt="modepaiement" className="img-fluid" style={{ height: "70px" }} />
                                            
                                                <img src="/celtis.png" alt="modepaiement" className="img-fluid" style={{ height: "70px" }} />
                                             */}
                      {/** </div>
                                            </div> */}

                      <div className="row">
                        <div className="d-flex flex-wrap gap-2 justify-content-start">
                          <Button
                            type="submit"
                            variant="success"
                            disabled={isSubmitting}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Enregistrement en cours...
                              </>
                            ) : (
                              "Enregistrer et payer"
                            )}
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={handleBack}
                            disabled={loading}
                          >
                            Annuler
                          </Button>
                        </div>
                      </div>
                    </Form>

                    {/**
                                         * <Modal show={loading} backdrop="static" keyboard={false} centered>
                                            <Modal.Body className="text-center">
                                                <ProgressSpinner />
                                                <p className="my-3 fw-bold" style={{ fontSize: '18px' }}>Veuillez patienter pendant l'enregistrement de vos données...</p>
                                            </Modal.Body>
                                        </Modal>
                                         */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </section>
        <section className="footer-login">
          <div className="col-md-12 d-flex w-100 ">
            <div
              className="col-md-4"
              style={{ height: "8px", backgroundColor: "#008559" }}
            ></div>
            <div
              className="col-md-4"
              style={{ height: "8px", backgroundColor: "#FFD400" }}
            ></div>
            <div
              className="col-md-4"
              style={{ height: "8px", backgroundColor: "#E43009" }}
            ></div>
          </div>
          <div className="container text-center py-2">
            <small className="copyright">
              &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE -
              COTONOU | Tous droits réservés.
              {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
            </small>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
