import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { RadioButton } from "primereact/radiobutton";
import { formatAmount } from "../utils/helpers";
import { useMediaQuery } from "@mui/material";

const PaymentModal = ({
  visible,
  onHide,
  montant,
  userInfo,
  setUserInfo,
  onPay,
}) => {
  const [modePaiement, setModePaiement] = useState(null);

  const modes = [
    { code: "mtn", libelle: "MTN", icon: "pi pi-mobile" },
    { code: "moov", libelle: "MOOV", icon: "pi pi-mobile" },
    { code: "celtis", libelle: "CELTIS", icon: "pi pi-mobile" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSetModePaiement = (e) => {
    setModePaiement(e.value);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

   // Composant pour les colonnes
   const PaymentModes = () => (
    <div className="payment-column">
      <div className="payment-header">Montant à payer : {montant ? formatAmount(montant) : "0 F CFA"}</div>
      <h6 className="text-center mb-3">Choisissez votre mode de paiement</h6>
      <div className={`${isMobile ? "payment-options-mobile" : "payment-options"}`}>
        {modes.map((item, index) => (
          <div
            className={`payment-option ${isMobile && " mb-2"}`}
            key={index}
            onMouseEnter={(e) => addHoverEffect(e.currentTarget)}
            onMouseLeave={(e) => removeHoverEffect(e.currentTarget)}
          >
            <RadioButton
              inputId={`option-${index}`}
              value={item.code}
              onChange={handleSetModePaiement}
              checked={modePaiement === item.code}
            />
            <label htmlFor={`option-${index}`} className="payment-label">
              {item.libelle}
            </label>
          </div>
        ))}
      </div>
      <div className="image-container">
        <img src="/paiement.png" alt="modepaiement" className="img-fluid" />
      </div>
    </div>
  );

  const PaymentInfo = () => (
    <div className="payment-column">
      <div className="payment-header">Informations de paiement</div>
      <div className="form-group">
        <div className="form-row">
        <label htmlFor="nom">Nom</label>
          <InputText id="nom" name="nom" value={userInfo.nom} onChange={handleInputChange} placeholder="Ex: Koudjo" className="mb-2"/>
          <label htmlFor="prenoms">Prénoms</label>
          <InputText
            id="prenoms"
            name="prenoms"
            value={userInfo.prenoms}
            onChange={handleInputChange}
            placeholder="Ex: Jean"
            className="mb-2"
          />
        </div>
        <label htmlFor="email">Email</label>
        <InputText
          id="email"
          name="email"
          value={userInfo.email}
          onChange={handleInputChange}
          placeholder="Ex: jean.koudjo@example.com" className="mb-2"
        />
        <label htmlFor="phone">Compte Mobile Money</label>
        <InputMask
          id="phone"
          name="phone"
          mask="+229 0199999999"
          value={userInfo.phone}
          onChange={handleInputChange}
          placeholder="+229 0199999999"
        />
      </div>
    </div>
  );

  const ActionButtons = () => (
    <div className={`${isMobile ? "action-buttons-mobile" : "action-buttons"}`}>
      <Button
        label={`Payer ${montant ? formatAmount(montant) : "0 F CFA"}`}
        icon="pi pi-check"
        className="p-button-success"
        disabled={!modePaiement}
        onClick={onPay}
      />
      <Button label="Annuler" icon="pi pi-times" severity="warning" onClick={onHide} />
    </div>
  );

  // Effets hover pour les options
  const addHoverEffect = (target) => {
    target.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.15)";
    target.style.transform = "translateY(-3px)";
  };

  const removeHoverEffect = (target) => {
    target.style.boxShadow = "0 2px 6px rgba(0, 0, 0, 0.05)";
    target.style.transform = "translateY(0)";
  };

  return (
    <Dialog
      header="Paiement avec TresorPay"
      visible={visible}
      onHide={onHide}
      style={{ width: isMobile ? "90vw" : "55vw", maxWidth: "700px" }}
      className="p-fluid"
      maximizable
    >
      <div className={`dialog-content ${isMobile ? "flex-column" : "flex-row"}`}>
        <PaymentModes />
        <PaymentInfo />
      </div>
      <ActionButtons />
    </Dialog>
  );
};

export default PaymentModal;
