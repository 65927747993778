import Swal from "sweetalert2";
import $ from "jquery";
import StatutDossierEnum from "../enum/statutDossierEnum";
import AdministrationService from "../services/AdministrationService";
import JonctionsService from "../services/JonctionsService";
import sanitizeHtml from "sanitize-html";

import { createRoot } from "react-dom/client";
import { FedaCheckoutButton } from "fedapay-reactjs";
// Fonction pour afficher les messages d'erreur avec SweetAlert
export function afficherMessagesErreur(errors) {
  let messageErreur = "";

  // Parcourir les clés de l'objet erreur
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      // Concaténer les messages d'erreur pour chaque clé
      messageErreur += `${key}: ${errors[key].join("<br>")}\n`; // Utilisation de '<br>' pour aller à la ligne
    }
  }

  // Afficher les messages d'erreur dans une boîte de dialogue SweetAlert
  Swal.fire({
    icon: "error",
    title: "Erreur de validation",
    html: messageErreur,
    toast: true,
    position: "top-end",
    timer: 10000,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Fermer",
    timerProgressBar: true,
  });
}

export function afficherMessagesSuccess(message) {
  Swal.fire({
    icon: "success",
    title: message,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "green",
    color: "white",
    iconColor: "white",
  });
}

export function afficherOneMessageError(message) {
  Swal.fire({
    icon: "error",
    title: message,
  });
}

export function copyToClipboard(ref) {
  //var copyText = document.getElementById(elementId);

  navigator.clipboard.writeText(ref);
  // afficher alert copied
  afficherMessagesSuccess("Texte copié ! : " + ref);
}

export function convertDateToTexte(dateString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(dateString).toLocaleDateString("fr-FR", options);
}

//Formater la date en Mer. 18 Dec. 2024
export function convertDateToMinTexte(dateInput) {
  // Crée un tableau pour les jours abrégés
  const jours = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];

  // Crée un tableau pour les mois abrégés
  const mois = [
    "janv.",
    "févr.",
    "mars",
    "avr.",
    "mai",
    "juin",
    "juil.",
    "août",
    "sept.",
    "oct.",
    "nov.",
    "déc.",
  ];

  // Crée un objet Date à partir de l'entrée
  const date = new Date(dateInput);

  // Récupère les composants formatés
  const jour = jours[date.getDay()];
  const numeroJour = date.getDate();
  const moisAbrege = mois[date.getMonth()];
  const annee = date.getFullYear();

  // Retourne la chaîne formatée
  return `${jour} ${numeroJour} ${moisAbrege} ${annee}`;
}

export function getLastPartWithoutExtension(str) {
  // Trouver l'index de la dernière barre oblique "/"
  const lastSlashIndex = str.lastIndexOf("/");

  // Extraire la partie après la dernière barre oblique
  let lastPart = str.substring(lastSlashIndex + 1);

  // Supprimer l'extension ".pdf"
  lastPart = lastPart.replace(".pdf", "");

  return lastPart;
}

export function getPaiementConfig(code) {
  return {
    key:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_KKIAPAY_PUBLIC_KEY
        : process.env.REACT_APP_KKIAPAY_SANDBOX_PUBLIC_KEY,
    isSandbox: process.env.NODE_ENV === "production" ? false : true,
  };
}

export function setDataTable() {
  // initialize datatable
  $(document).ready(function () {
    $(".data-table").DataTable({
      buttons: ["copy", "csv", "excel", "pdf", "print"],
      layout: {
        topStart: "buttons",
      },
      ordering: true,
      language: {
        sProcessing: "Traitement en cours ...",
        //sLengthMenu: "Afficher _MENU_ lignes",
        sZeroRecords: "Aucun résultat trouvé",
        sEmptyTable: "Aucune donnée disponible",
        sLengthMenu: "Afficher &nbsp; _MENU_ &nbsp;",
        sInfo: "_START_ ... _END_/_TOTAL_ &eacute;l&eacute;ments",
        sInfoEmpty: "Aucune ligne affichée",
        sInfoFiltered: "(Filtrer un maximum de _MAX_)",
        sInfoPostFix: "",
        sSearch: "Recherche",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Chargement...",
        oPaginate: {
          sFirst: "Premier",
          sLast: "Dernier",
          sNext: "Suivant",
          sPrevious: "Précédent",
        },
        oAria: {
          sSortAscending: ": Trier par ordre croissant",
          sSortDescending: ": Trier par ordre décroissant",
        },
      },
      dom: '<"float-left"l><"float-right"f>Brti<"float-right"p>',
      //   stateSave : true,
      order: [[0, "asc"]],
      processing: true,
      serverSide: false,
      retrieve: true,
      //paging: false
    });
  });
}

export function filterStatut(liste_statuts) {
  const filteredData = liste_statuts.filter(
    (option) =>
      option.libelle == "ACTIF" ||
      option.libelle == "INACTIF" ||
      option.libelle == "BROUILLON"
  );

  return filteredData;
}

export function checkProcedureHelper(procedure_id, procedures) {
  if (procedure_id) {
    const checkFirstValue = procedures.find(
      (option) =>
        option.libelle == "AVENIR D'AUDIENCE" && option.id == procedure_id
    );
    const checkSecondValue = procedures.find(
      (option) =>
        option.libelle == "AUTRES PROCEDURES EN APPEL" &&
        option.id == procedure_id
    );

    if (checkFirstValue || checkSecondValue) {
      return true;
    }
  }

  return false;
}

export function checkIsAutreProcedureHelper(procedure_id, procedures) {
  if (procedure_id) {
    const checkFirstValue = procedures.find(
      (option) =>
        option.libelle == "AUTRES PROCEDURES EN APPEL" &&
        option.id == procedure_id
    );

    //Verifie s'il s'agit de AUTRES PROCEDURES EN APPEL
    if (checkFirstValue) {
      //setIsAutreProcedure(true)
      return true;
    }
  }

  //setIsAutreProcedure(false)
  return false;
}

//Ordonner les dates par ordre croissant
export function sortedDates(dateString) {
  // Trier les dates par ordre croissant
  const sortedDates = dateString.sort((a, b) => {
    const dateA = new Date(a.jour);
    const dateB = new Date(b.jour);
    return dateA - dateB;
  });

  return sortedDates;
}

export function afficherErrorToast(message) {
  Swal.fire({
    icon: "error",
    title: message,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "red",
    color: "white",
    iconColor: "white",
  });
}

//fonction pour adapter les couleurs selon le statut du dossier
export function getColorStatus(status) {
  switch (true) {
    case StatutDossierEnum.isStatusNouveau(status):
      return "#378006";
    case StatutDossierEnum.isStatusRenvoye(status):
      return "#dc3545";
    case StatutDossierEnum.isStatusEnDelibere(status):
      return "#FF9800";
    case StatutDossierEnum.isStatusRadie(status):
      return "#7DCBC9";
    case StatutDossierEnum.isStatusVide(status):
      return "#5c636a";
    case StatutDossierEnum.isStatusJointure(status):
      return "#2b636a";
    default:
      return "#000000"; // Couleur par défaut si aucun statut ne correspond
  }
}

/**
 * Formatte un montant en fonction des options fournies.
 *
 * @param {number|string} amount - Le montant à formater.
 * @param {string} locale - La locale utilisée pour le formatage (ex : "fr-FR").
 * @param {string} currency - La devise à afficher (ex : "EUR", "USD", "XOF").
 * @returns {string} - Le montant formaté.
 */
export const formatAmount = (amount, locale = "fr-FR", currency = "XOF") => {
  if (isNaN(amount)) {
    console.warn("Le montant fourni n'est pas un nombre valide :", amount);
    return "Montant invalide";
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2, // Par défaut, deux décimales.
    maximumFractionDigits: 2, // Pour éviter trop de décimales.
  });

  return formatter.format(amount);
};

//Obtenir la date la plus récente
export const getLatestDate = (dates) => {
  if (!dates || dates.length === 0) return "N/A";

  // Transforme les dates en objets Date et trouve la plus récente
  const latestDate = dates
    .map((item) => new Date(item.get_calendrier?.jour)) // Convertit les chaînes en Date
    .reduce((max, date) => (date > max ? date : max), new Date(0)); // Trouve la plus récente

  return latestDate ? convertDateToMinTexte(latestDate) : "N/A";
};

//Télécharger séparement les fichiers du système
export const downloadSingleFile = async (path, token) => {
  await AdministrationService.downloadFile({ filename: path }, token)
    .then((response) => {
      if (response.status == 200) {
        // Créez une URL pour le téléchargement du fichier
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        let name = path.split("/");

        // Ajoutez le nom du fichier à télécharger
        link.setAttribute("download", name[2] ?? "dossier.pdf");
        document.body.appendChild(link);
        link.click();

        // Nettoyez le DOM après l'utilisation
        link.parentNode.removeChild(link);

        afficherMessagesSuccess("Le fichier a été téléchargé avec succès.");
      } else {
        afficherMessagesErreur(response.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      afficherMessagesErreur(error.response.data?.message);
    });
};

export const findDossierFinal = async (dossier_id, token) => {
  await JonctionsService.index(token)
    .then((response) => {
      if (response.status == 200) {
        const dossier = response.data.data.find(
          (item) => item.dossier_source_id == dossier_id
        );
        return dossier ? dossier.reference_dossier : "";
      } else {
        afficherMessagesErreur(response.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      afficherMessagesErreur(error.response.data?.message);
    });
};

export const formatAmountWithoutCFA = (
  amount,
  locale = "fr-FR",
  currency = "XOF"
) => {
  if (isNaN(amount)) {
    console.warn("Le montant fourni n'est pas un nombre valide :", amount);
    return "Montant invalide";
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: "decimal", // Utiliser le style "decimal" pour obtenir le formatage sans le symbole de la devise
    //currency: currency,
    //minimumFractionDigits: 0, // Par défaut, deux décimales.
    //maximumFractionDigits: 2, // Pour éviter trop de décimales.
  });

  // Formater le montant sans le symbole de la devise
  const formattedAmount = formatter.format(amount);

  // Ajouter manuellement le symbole "F" à la fin
  return `${formattedAmount} F`;
};

export const htmlFormater = (data) => {
  return (
    data &&
    sanitizeHtml(data, {
      allowedTags: [],
      allowedAttributes: {},
    })
  );
};

export const initiateFedaPayCheckout = ({
  amount = 100,
  description = "Cour d'Appel de Commerce de Cotonou",
  currencyISO = "XOF",
  client = null,
  onCompleteCallback,
}) => {
  const PUBLIC_KEY =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_FEDAPAY_PUBLIC_KEY
      : process.env.REACT_APP_FEDAPAY_SANDBOX_PUBLIC_KEY;

  const BUTTON_CONTAINER_ID = "feda-pay-button-container";

  // Vérifier si le conteneur existe déjà, sinon le créer
  let container = document.getElementById(BUTTON_CONTAINER_ID);
  if (!container) {
    container = document.createElement("div");
    container.id = BUTTON_CONTAINER_ID;
    container.style.display = "none"; // Garder invisible
    document.body.appendChild(container);
  }

  // Options de paiement pour FedaPay
  const checkoutButtonOptions = {
    public_key: PUBLIC_KEY,
    transaction: {
      amount,
      description,
    },
    currency: {
      iso: currencyISO,
    },
    customer: client,
    button: {
      class: "btn btn-primary",
      text: `Payer ${amount} F CFA`, // N'a pas d'impact, le bouton sera caché
    },
    onComplete(resp) {
      if (typeof onCompleteCallback === "function") {
        onCompleteCallback(resp);
      }
    },
  };

  // Rendre dynamiquement le composant React dans le conteneur
  const root = createRoot(container);
  root.render(<FedaCheckoutButton options={checkoutButtonOptions} />);

  // Simuler un clic sur le bouton caché après rendu
  setTimeout(() => {
    const hiddenButton = container.querySelector("button");
    if (hiddenButton) {
      hiddenButton.click();
    } else {
      console.error("Le bouton FedaPay n’a pas été trouvé.");
    }
  }, 100); // Laisser le temps au DOM de se mettre à jour
};

// Fonction utilitaire pour charger le script TresorPay
export const loadTresorPayScript = () => {
  return new Promise((resolve, reject) => {
    // Vérifier si le script est déjà chargé
    if (
      document.querySelector(
        'script[src="https://cdn.tresorpay.bj/checkout.js?v=1.1.7"]'
      )
    ) {
      //console.log("Le script TresorPay est déjà chargé.");
      resolve();
      return;
    }

    // Créer et charger le script
    const script = document.createElement("script");
    script.src = "https://cdn.tresorpay.bj/checkout.js?v=1.1.7";
    script.async = true;
    script.onload = () => {
      //console.log("TresorPay script loaded");
      resolve(); // Script chargé avec succès
    };
    script.onerror = () => {
      console.error("Failed to load TresorPay script");
      reject(new Error("Failed to load TresorPay script"));
    };
    document.body.appendChild(script);
  });
};

/***
     * La clé SIR_CLASS prendra une des valeurs :
      - 1528 pour DEMANDE DE GROSSE -> 7.000
      - ⁠1527 pour DEMANDE DE COPIE D'ARRET -> 2.000
      - ⁠1526 pour ENROLEMENT DES AFFAIRES -> 10.000
*/
export function getSirClass(montant_payer) {
  let code;

  switch (montant_payer) {
    case 7000:
      code = "1528";
      break;
    case 2000:
      code = "1527";
      break;
    case 10000:
      code = "⁠1526";
    break;
    default:
      code = 'test'; // ou une valeur par défaut si nécessaire
  }

  return code;
}
