import { KkiapayStrategy, FedaPayStrategy, TresorPayStrategy } from "./PaymentStrategies";

const PaymentHandler = {
  strategies: {
    KKIAPAY: KkiapayStrategy,
    FEDAPAY: FedaPayStrategy,
    TRESORPAY: TresorPayStrategy
  },
  executeStrategy: (modepaiement_id, params) => {
    try {
      const strategy = PaymentHandler.strategies[modepaiement_id];
      if (!strategy) {
        throw new Error(`Aucune stratégie trouvée pour le mode de paiement: ${modepaiement_id}`);
      }
      strategy.execute(params);
    } catch (error) {
      console.error("Erreur lors de l'exécution de la stratégie :", error);
      throw error; // Propager l'erreur pour la gestion dans l'UI
    }
  },  
};

export default PaymentHandler;
